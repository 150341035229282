<template>
  <div>
    <h6>Action taking user tags :</h6>
    <span>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#clientloginurl#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#accesscode#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#mobileapplink#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#usershortname#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#userfullname#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#useremailid#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#userprimarymobilenumber#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#userpassword#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#useraadharnumber#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#usercurrentaddress#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#userphotofilename#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#clientname#]</Chip>
    </span>
    <Divider />
    <h6>Admin employee tags :</h6>
    <span>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#clientloginurl#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#accesscode#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#mobileapplink#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#adminusershortname#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#adminuserfullname#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#adminuseremailid#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#adminuserprimarymobilenumber#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#adminuserpassword#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#adminuseraadharnumber#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#adminusercurrentaddress#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#adminuserphotofilename#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#clientname#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#loginusername#]</Chip>
    </span>
    <Divider />
    <h6>Voter tags :</h6>
    <span>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#clientloginurl#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#accesscode#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#mobileapplink#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#votersfirstname#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#votershortname#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#voterfullname#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#voteremailid#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#voterprimarymobilenumber#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#voterpassword#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#voteraadharnumber#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#votercurrentaddress#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#voterphotofilename#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#clientname#]</Chip>
    </span>
    <Divider />
    <h6>Support user tags :</h6>
    <span>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#clientloginurl#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#accesscode#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#mobileapplink#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#supportusershortname#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#supportuserfullname#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#supportuseremailid#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#supportuserprimarymobilenumber#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#supportuserpassword#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#supportuseraadharnumber#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#supportusercurrentaddress#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#supportuserphotofilename#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#clientname#]</Chip>

    </span>
    <Divider />
    <h6>Current user tags :</h6>
    <span>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#clientloginurl#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#accesscode#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#mobileapplink#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#usershortname#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#userfullname#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#useremailid#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#userprimarymobilenumber#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#userpassword#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#useraadharnumber#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#usercurrentaddress#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#userphotofilename#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan side-padding">[#clientname#]</Chip>
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
};
</script>
<style>
.side-padding
{
  padding: 3px 10px;
}
</style>